import type { CustomFlowbiteTheme } from "flowbite-react";
import { Flowbite,DarkThemeToggle } from "flowbite-react";

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const customTheme: CustomFlowbiteTheme = {
  darkMode: 'media',

  button: {
    color: {
      primary: "bg-orange-500 hover:bg-orange-600 text-white",
    },
  },
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App page="main" />,
  },
  {
    path: "/auth-mesh",
    element: <App page="auth-mesh" />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Flowbite theme={{ theme: customTheme, mode: 'dark' }}>
    <div className="bg-gray-100 dark:bg-slate-900 h-full w-full">
      <RouterProvider router={router} />
    </div>
    <DarkThemeToggle className="hidden"/>
  </Flowbite>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
