import { lazy } from 'react';
const AuthMesh = lazy(() => import('./Views/AuthMesh'));

function App(args) {
  switch(args.page){
    case "auth-mesh":
   return(<AuthMesh/>);
  }
}

export default App;
